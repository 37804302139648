@import "src/styles/common";

.topBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5em;
  padding-top: 1.5em;
  @media (max-width: $screen-xxs) {
    padding: 1rem;
    padding-left: 0.5rem;
    svg {
      max-width: 25%;
    }
  }
}

.wrapTrustPilot {
  @media (max-width: $screen-xxs) {
    iframe {
      width: 10rem;
      height: 3rem !important;
    }
    svg {
      max-width: unset;
    }
  }
}
